import { FormHelperText, makeStyles } from '@material-ui/core';
import { DTODesign } from 'models';
import React, { FC, KeyboardEvent, useContext } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { FontSelectProps, FontSizeProps } from '../../../constants/general/designs';
import noImageImg from '../../../images/settings/image-white.svg';
import { UIContext } from '../../../modules/general/ui';
import { DesignContext, designOperations } from '../../../modules/settings/design';
import { findNextTabStop } from '../../../utils/findNextTabStop';
import { ClassName } from '../../general/props/classname';
import { AdminButton } from '../../general/units/adminButton';
import { AdminColorInput } from '../parts/adminColorInput';
import { AdminInput } from '../parts/adminInput';
import { AdminSelect } from '../parts/adminSelect';
import { AdminImageInputWithCrop } from './adminImageInputWithCrop';

type Props = ClassName & {
  onChange: {
    changeHeaderImage: (base64image: string) => void;
    deleteHeaderImage: () => void;
    changeHeaderBackgroundColor: (value: string) => void;
    changeHeaderText: (value: string) => void;
    changeHeaderTextColor: (value: string) => void;
    changeHeaderFontFamily: (value: string) => void;
    changeHeaderFontSize: (value: string) => void;
    resetHeaderForm: () => void;
  };
  image: string;
  design: DTODesign | undefined;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  inputGroup: {
    margin: '1rem 0',
  },
  label: {
    display: 'block',
    fontSize: '1.1rem',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    marginBottom: '0.2rem',
  },
  disabledLabel: {
    color: '#969696',
  },
  saveButton: {
    marginLeft: '2rem',
  },
  error: {
    color: 'red',
    height: '0.75rem',
  },
}));

export const HeaderDesignForm: FC<Props> = ({ className, onChange, image, design }) => {
  const classes = useStyles();

  const uiStore = useContext(UIContext);
  const designStore = useContext(DesignContext);

  const changeImage = (base64image: string) => {
    onChange.changeHeaderImage(base64image);
  };

  const changeBackgroundColor = (value: string) => {
    onChange.changeHeaderBackgroundColor(value);
  };

  const changeText = (value: string) => {
    onChange.changeHeaderText(value);
  };

  const changeTextColor = (value: string) => {
    onChange.changeHeaderTextColor(value);
  };

  const changeFontFamily = (value: string) => {
    onChange.changeHeaderFontFamily(value);
  };

  const changeFontSize = (value: string) => {
    onChange.changeHeaderFontSize(value);
  };

  const resetForm = () => {
    onChange.resetHeaderForm();
  };

  const saveDesign = () => {
    if (design) {
      designOperations.updateDesign(uiStore.dispatch, designStore.dispatch, design);
    }

    if (image && design?.isSetupHeaderImage) {
      designOperations.uploadHeaderImage(uiStore.dispatch, designStore.dispatch, image);
    }
  };

  const onEnterDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      findNextTabStop(e.target).focus();
    }
  };

  return !design ? (
    <></>
  ) : (
    <div className={className}>
      <div className={classes.inputGroup}>
        <label className={classes.label} htmlFor="header-background-image">
          画像
        </label>
        <AdminImageInputWithCrop
          id="header-background-image"
          alt="ヘッダー画像"
          src={image || (design.isSetupHeaderImage ? '/images/header.png' : noImageImg)}
          aspect={16 / 9}
          onCropComplete={changeImage}
          onDeleteClick={onChange.deleteHeaderImage}
        />
      </div>
      <div className={classes.inputGroup}>
        <label className={classes.label} htmlFor="header-background-color">
          背景色
        </label>
        <AdminColorInput
          id="header-background-color"
          value={design.headerBackgroundColor}
          onChange={changeBackgroundColor}
        />
      </div>
      <div className={classes.inputGroup}>
        <label className={classes.label} htmlFor="header-text">
          文字
        </label>
        <AdminInput id="header-text" value={design.headerText} onChange={changeText} onKeyDown={onEnterDown} />
        <FormHelperText className={classes.error}>
          {design.headerText.length > 100 ? '文字は100字以内で入力してください' : ''}
        </FormHelperText>
      </div>
      <div className={classes.inputGroup}>
        <label
          className={`${classes.label} ${design.headerText.length === 0 && classes.disabledLabel}`}
          htmlFor="header-text-color"
        >
          文字色
        </label>
        <AdminColorInput
          id="header-text-color"
          value={design.headerTextColor}
          onChange={changeTextColor}
          disabled={design.headerText.length === 0}
        />
      </div>
      <div className={classes.inputGroup}>
        <label
          className={`${classes.label} ${design.headerText.length === 0 && classes.disabledLabel}`}
          htmlFor="header-font-family"
        >
          フォント
        </label>
        <AdminSelect
          id="header-font-family"
          selectProps={FontSelectProps}
          selectedValue={design.headerFontFamily}
          onChange={changeFontFamily}
          disabled={design.headerText.length === 0 && true}
        />
      </div>
      <div className={classes.inputGroup}>
        <label
          className={`${classes.label} ${design.headerText.length === 0 && classes.disabledLabel}`}
          htmlFor="header-font-size"
        >
          フォントサイズ
        </label>
        <AdminSelect
          id="header-font-size"
          selectProps={FontSizeProps}
          selectedValue={`${design.headerFontSize}`}
          onChange={changeFontSize}
          disabled={design.headerText.length === 0 && true}
        />
      </div>
      <AdminButton color="default" text="元に戻す" onClick={resetForm} />
      <AdminButton
        className={classes.saveButton}
        color="positive"
        text="保存"
        onClick={saveDesign}
        disabled={design.headerText.length > 100}
      />
    </div>
  );
};
