import { ButtonBase, Card, makeStyles, Typography } from '@material-ui/core';
import { DTODesign, DTOProduct } from 'models';
import React, { FC, useContext } from 'react';
import { TaxContext } from '../../../modules/general/tax';
import { Yen } from '../../general/parts/Yen';
import { ClassName } from '../../general/props/classname';

const CARD_HEIGHT = 120 as const;

type Props = ClassName & {
  product: DTOProduct;
  designState: DTODesign | undefined;
};

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    width: '100%',
    height: CARD_HEIGHT,
    backgroundColor: '#ffffff',
  },
  innerWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row',
  },
  image: {
    width: CARD_HEIGHT,
    height: CARD_HEIGHT,
    flexGrow: 0,
    borderRight: 'solid 0.5px #e6e6e6',
  },
  infoWrapper: {
    display: 'flex',
    height: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'left',
    flexGrow: 1,
  },
  productName: {
    margin: '0.7rem',
    fontWeight: 700,
    wordBreak: 'break-all',
    fontSize: '1.1rem',
    '@media (min-width: 1024px)': {
      fontSize: '0.9rem',
    },
    '@media (min-width: 1140px)': {
      fontSize: '1.1rem',
    },
    '@media (max-width: 350px)': {
      fontSize: '0.9rem',
    },
  },
  productMeta: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    width: '96%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  productPrice: {
    fontSize: '1rem',
    '@media (min-width: 1024px)': {
      fontSize: '1rem',
    },
  },
}));

export const PreviewCard: FC<Props> = ({ className, product, designState }) => {
  const classes = useStyles();

  const taxStore = useContext(TaxContext);

  return (
    <Card className={`${classes.card} ${className}`}>
      <ButtonBase className={classes.innerWrapper}>
        {product.hasImage && (
          <div className={classes.image}>
            <img src={`/images/products/${product.id}.png`} alt={`${product.name}の写真`} width={CARD_HEIGHT} />
          </div>
        )}
        <div className={classes.infoWrapper}>
          <Typography
            className={classes.productName}
            variant="h5"
            style={designState && { fontFamily: `${designState.menuFontFamily}, sans-serif` }}
          >
            {product.name}
          </Typography>
          <div className={classes.productMeta}>
            <Yen className={classes.productPrice} variant="h5" price={product.price} tax={taxStore.state.tax?.rate} />
          </div>
        </div>
      </ButtonBase>
    </Card>
  );
};
