import { makeStyles } from '@material-ui/core';
import { DTODesign } from 'models';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';
import { KeepListIconButton } from '../parts/keepListIconButton';

type Props = ClassName & {
  badgeContent: number;
  onClick?: () => void;
  designState?: DTODesign | undefined;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    top: 'auto',
    bottom: -1, // NOTE: Pixel2XL の下部スキマ排除
    width: '100%',
    flexDirection: 'row',
    boxShadow: 'none',
    justifyContent: 'flex-end',
    position: 'fixed',
    paddingBottom: '5%',
  },
}));

export const KeepListBottomNavigation: FC<Props> = ({ className, badgeContent, onClick, designState }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.wrapper} ${className}`}>
      <KeepListIconButton badgeContent={badgeContent} onClick={onClick} />
    </div>
  );
};
