import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  value: string;
  id?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    width: '60px',
    height: '30px',
    border: '1px solid #969696',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  value: {
    marginLeft: '0.5rem',
    color: '#333333',
  },
  disabledText: {
    color: '#969696',
  },
}));

export const AdminColorInput: FC<Props> = ({ className, value, id, disabled = false, onChange }) => {
  const classes = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <div className={`${classes.wrapper} ${className} `}>
      {!disabled && (
        <input
          id={id}
          className={classes.input}
          value={value}
          type="color"
          onChange={handleChange}
          disabled={disabled}
        />
      )}
    </div>
  );
};
