import { Badge, IconButton, makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import keepList from '../../../images/settings/keepList-icon.svg';

interface Props {
  badgeContent: number;
  onClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    fontSize: '2.5rem', // inherited by FooterButtonIcon
    flexDirection: 'column',
    flexGrow: 1,
  },
  menuIcon: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    position: 'relative',
    fill: 'currentColor',
    filter: 'drop-shadow(2px 2px 2px rgba(0,0,0,0.4))',
    backgroundColor: '#ffffff',
  },
  image: {
    paddingBottom: '10%',
    width: '50%',
    '&:hover': { filter: 'brightness(90%) saturate(150%)' },
  },
  text: {
    color: theme.palette.text.primary,
    paddingTop: '65%',
    position: 'absolute',
  },
}));

export const KeepListIconButton: FC<Props> = ({ badgeContent, onClick }) => {
  const classes = useStyles();
  return (
    <IconButton onClick={onClick}>
      <Badge badgeContent={badgeContent} color="primary" overlap="circle">
        <div className={classes.menuIcon}>
          <img className={classes.image} src={keepList} alt="keepリストボタン" />
          <Typography variant="body2" className={classes.text}>
            keep
          </Typography>
        </div>
      </Badge>
    </IconButton>
  );
};
