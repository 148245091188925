import { makeStyles, ThemeProvider } from '@material-ui/core';
import React, { FC } from 'react';
import { DesignLayout } from '../../../components/settings/layouts/designLayout';
import { menuTheme } from '../../../components/settings/theme/menuTheme';
import { settingsTheme } from '../../../components/settings/theme/settingsTheme';
import { UIContextProvider } from '../../../modules/general/ui';
import { AdminMenuContextProvider } from '../../../modules/settings';
import { DesignContextProvider } from '../../../modules/settings/design';
import { MenuContextProvider } from '../../../modules/settings/menu';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
}));

const DesignPage: FC = () => {
  const classes = useStyles();

  return (
    <AdminMenuContextProvider>
      <DesignContextProvider>
        <MenuContextProvider>
          <UIContextProvider>
            <ThemeProvider theme={menuTheme}>
              <ThemeProvider theme={settingsTheme}>
                <div className={classes.wrapper}>
                  <DesignLayout />
                </div>
              </ThemeProvider>
            </ThemeProvider>
          </UIContextProvider>
        </MenuContextProvider>
      </DesignContextProvider>
    </AdminMenuContextProvider>
  );
};

export default DesignPage;
