/**
 * カラーコードと明るさ入れると元の色より明るい色をHSLの値に変換して返してくれるやつ
 *
 * @param colorCode #ffffffのようにカラーコードを入れてくだされ
 *  @param saturat 彩度を入れてくだされ(10%明るくしたいなら10って入れる)
 * @param light どのくらい明るくしたいか(輝度)いれてくだされ(10%明るくしたいなら10って入れる)
 */

export const colorTranslator = (colorCode: string, saturat?: number, light?: number) => {
  const rgbRed = parseInt(colorCode.substring(1, 3), 16);
  const rgbGreen = parseInt(colorCode.substring(3, 5), 16);
  const rgbBlue = parseInt(colorCode.substring(5, 7), 16);

  const max = Math.max(rgbRed, rgbGreen, rgbBlue);
  const min = Math.min(rgbRed, rgbGreen, rgbBlue);
  const diff = max - min;

  const hueCalc = () => {
    if (max === min) {
      return 0;
    }
    if (max === rgbRed) {
      return 60 * ((rgbGreen - rgbBlue) / diff);
    }
    if (max === rgbGreen) {
      return 60 * ((rgbBlue - rgbRed) / diff) + 120;
    }
    return 60 * ((rgbRed - rgbGreen) / diff) + 240;
  };
  const resultHue = hueCalc();
  const correctResultHue = () => {
    if (resultHue < 0) {
      return resultHue + 360;
    }
    return resultHue;
  };
  const hue = correctResultHue();

  const saturationCalc = () => {
    const cnt = (max + min) / 2;
    if (cnt > 255 / 2) {
      return ((max - min) / (255 * 2 - max - min)) * 100;
    }
    if (max + min <= 0) {
      return 0;
    }
    return (diff / (max + min)) * 100;
  };
  const saturation = saturationCalc();

  const lightness = ((max + min) / 255 / 2) * 100;

  const addSaturationAndLightness = () => {
    if (!saturat && typeof light === 'number') {
      return {
        saturation: 0,
        lightness: light,
      };
    }
    if (!light && typeof saturat === 'number') {
      return {
        saturation: saturat,
        lightness: 0,
      };
    }
    if (typeof saturat === 'number' && typeof light === 'number') {
      return {
        saturation: saturat,
        lightness: light,
      };
    }

    return {
      saturation: 0,
      lightness: 0,
    };
  };

  const addCount = addSaturationAndLightness();
  return {
    h: hue,
    s: saturation + addCount.saturation,
    l: lightness + addCount.lightness,
  };
};

/**
 * 背景色に合わせて要素の明暗を変換してくれる
 * @param backgroundColor 背景の色（rgb）
 * @param light 要素の明るい色
 * @param dark 要素の暗い色
 * @param defaultColor 背景情報がないときの要素の色
 */
export const getPoliticColor = (
  backgroundColor: string | undefined,
  light: string,
  dark: string,
  defaultColor: string
) => {
  if (backgroundColor) {
    const r = parseInt(backgroundColor.substr(1, 2), 16);
    const g = parseInt(backgroundColor.substr(3, 2), 16);
    const b = parseInt(backgroundColor.substr(5, 2), 16);
    return (r * 299 + g * 587 + b * 114) / 1000 < 128 ? light : dark;
  }
  return defaultColor;
};
