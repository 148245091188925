import { makeStyles, ThemeProvider } from '@material-ui/core';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useMountEffect } from '../../../hooks/useMountEffect';
import { AuthContext, authOperations } from '../../../modules/general/auth';
import { UIContext } from '../../../modules/general/ui';
import { DesignContext, designOperations } from '../../../modules/settings/design';
import { MenuContext, menuOperations } from '../../../modules/settings/menu';
import { UiMessage } from '../../general/parts/uiMessage';
import { AdminTitle } from '../parts/adminTitle';
import { menuTheme } from '../theme/menuTheme';
import { HeaderDesignForm } from '../units/headerDesignForm';
import { IconSideMenu } from '../units/iconSideMenu';
import { MainMenuDesignForm } from '../units/mainMenuDesignForm';
import { Preview } from '../units/preview';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  contents: {
    width: 'calc((100vw - 15.5rem) * 0.55)',
    padding: '1rem 2.5rem',
    backgroundColor: '#ededed',
    flexGrow: 1,
    overflowY: 'scroll',
    boxSizing: 'border-box',
  },
  headerForm: {
    marginBottom: '3rem',
  },
  mainForm: {
    marginBottom: '1rem',
  },
}));

export const DesignLayout: FC = () => {
  const classes = useStyles();
  const uiStore = useContext(UIContext);
  const authStore = useContext(AuthContext);
  const menuStore = useContext(MenuContext);
  const designStore = useContext(DesignContext);
  const [design, setDesign] = useState(designStore.state.design);
  const [image, setImage] = useState('');

  useMountEffect(() => {
    authOperations.checkLogin(authStore.dispatch);
    designOperations.getDesign(uiStore.dispatch, designStore.dispatch);
    menuOperations.getMenus(uiStore.dispatch, menuStore.dispatch);
  });

  useEffect(() => {
    setDesign(designStore.state.design);
  }, [designStore.state.design]);

  const changeHeaderImage = (base64image: string) => {
    if (!design || !base64image) {
      return;
    }

    setImage(base64image);
    setDesign({
      ...design,
      isSetupHeaderImage: true,
    });
  };

  const deleteHeaderImage = () => {
    setImage('');

    if (design) {
      setDesign({
        ...design,
        isSetupHeaderImage: false,
      });
    }
  };

  const changeHeaderBackgroundColor = (value: string) => {
    if (design) {
      setDesign({
        ...design,
        headerBackgroundColor: value,
      });
    }
  };

  const changeHeaderText = (value: string) => {
    if (design) {
      setDesign({
        ...design,
        headerText: value,
      });
    }
  };

  const changeHeaderTextColor = (value: string) => {
    if (design) {
      setDesign({
        ...design,
        headerTextColor: value,
      });
    }
  };

  const changeHeaderFontFamily = (value: string) => {
    if (design) {
      setDesign({
        ...design,
        headerFontFamily: value,
      });
    }
  };

  const changeHeaderFontSize = (value: string) => {
    if (design) {
      setDesign({
        ...design,
        headerFontSize: parseInt(value, 10),
      });
    }
  };

  const resetHeaderForm = () => {
    if (design && designStore.state.design) {
      setDesign({
        ...design,
        isSetupHeaderImage: designStore.state.design.isSetupHeaderImage,
        headerBackgroundColor: designStore.state.design.headerBackgroundColor,
        headerText: designStore.state.design.headerText,
        headerTextColor: designStore.state.design.headerTextColor,
        headerFontFamily: designStore.state.design.headerFontFamily,
        headerFontSize: designStore.state.design.headerFontSize,
      });
    }
  };

  const changeMenuCategoryColor = (value: string) => {
    if (design) {
      setDesign({
        ...design,
        menuCategoryColor: value,
      });
    }
  };

  const changeMenuBackgroundColor = (value: string) => {
    if (design) {
      setDesign({
        ...design,
        menuBackgroundColor: value,
      });
    }
  };

  const changeMenuFontFamily = (value: string) => {
    if (design) {
      setDesign({
        ...design,
        menuFontFamily: value,
      });
    }
  };

  const resetMainMenuForm = () => {
    if (design && designStore.state.design) {
      setDesign({
        ...design,
        menuCategoryColor: designStore.state.design.menuCategoryColor,
        menuBackgroundColor: designStore.state.design.menuBackgroundColor,
        menuFontFamily: designStore.state.design.menuFontFamily,
      });
    }
  };

  const onChangeHeader = {
    changeHeaderImage,
    deleteHeaderImage,
    changeHeaderBackgroundColor,
    changeHeaderText,
    changeHeaderTextColor,
    changeHeaderFontFamily,
    changeHeaderFontSize,
    resetHeaderForm,
  };

  const onChangeMainMenu = {
    changeMenuCategoryColor,
    changeMenuBackgroundColor,
    changeMenuFontFamily,
    resetMainMenuForm,
  };

  if (!authStore.state.user) return <></>;

  return (
    <div className={classes.wrapper}>
      <IconSideMenu />
      <div className={classes.contents}>
        <AdminTitle text="ヘッダー" />
        <HeaderDesignForm className={classes.headerForm} onChange={onChangeHeader} image={image} design={design} />
        <AdminTitle text="メインメニュー" />
        <MainMenuDesignForm className={classes.mainForm} onChange={onChangeMainMenu} design={design} />
      </div>
      <ThemeProvider theme={menuTheme}>
        <Preview design={design} image={image} />
      </ThemeProvider>
      <UiMessage />
    </div>
  );
};
