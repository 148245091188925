import { makeStyles, Typography } from '@material-ui/core';
import { DTODesign } from 'models';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  designState: DTODesign | undefined;
  image?: string;
};

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    height: '230px',
    backgroundColor: '#ffffff',
    position: 'relative',
  },
  headerImage: {
    width: '100%',
    height: '230px',
  },
  headerText: {
    position: 'absolute',
    fontSize: '50px',
    width: '80%',
    textAlign: 'center',
    wordBreak: 'break-all',
  },
  tmp: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const Header: FC<Props> = ({ className, designState, image }) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.header} ${classes.tmp} ${className}`}
      style={designState && { background: `${designState.headerBackgroundColor}` }}
    >
      {designState && designState.isSetupHeaderImage && (
        <img src={image || '/images/header.png'} className={classes.headerImage} alt="ヘッダー画像" />
      )}
      <Typography
        variant="h1"
        className={classes.headerText}
        style={
          designState && {
            fontFamily: `${designState.headerFontFamily}, sans-serif`,
            color: `${designState.headerTextColor}`,
            fontSize: `${designState.headerFontSize}px`,
          }
        }
      >
        {designState && designState.headerText}
      </Typography>
    </div>
  );
};
