import { makeStyles } from '@material-ui/core';
import { DTODesign } from 'models';
import React, { FC, useContext } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { FontSelectProps } from '../../../constants/general/designs';
import { UIContext } from '../../../modules/general/ui';
import { DesignContext, designOperations } from '../../../modules/settings/design';
import { ClassName } from '../../general/props/classname';
import { AdminButton } from '../../general/units/adminButton';
import { AdminColorInput } from '../parts/adminColorInput';
import { AdminSelect } from '../parts/adminSelect';

type Props = ClassName & {
  onChange: {
    changeMenuCategoryColor: (value: string) => void;
    changeMenuBackgroundColor: (value: string) => void;
    changeMenuFontFamily: (value: string) => void;
    resetMainMenuForm: () => void;
  };
  design: DTODesign | undefined;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  inputGroup: {
    margin: '1rem 0',
  },
  label: {
    display: 'block',
    fontSize: '1.1rem',
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    marginBottom: '0.2rem',
  },
  saveButton: {
    marginLeft: '2rem',
  },
}));

export const MainMenuDesignForm: FC<Props> = ({ className, onChange, design }) => {
  const classes = useStyles();

  const uiStore = useContext(UIContext);
  const designStore = useContext(DesignContext);

  const changeCategoryColor = (value: string) => {
    onChange.changeMenuCategoryColor(value);
  };

  const changeBackgroundColor = (value: string) => {
    onChange.changeMenuBackgroundColor(value);
  };

  const changeFontFamily = (value: string) => {
    onChange.changeMenuFontFamily(value);
  };

  const resetForm = () => {
    onChange.resetMainMenuForm();
  };

  const saveDesign = () => {
    if (design) {
      designOperations.updateDesign(uiStore.dispatch, designStore.dispatch, design);
    }
  };

  return !design ? (
    <></>
  ) : (
    <div className={className}>
      <div className={classes.inputGroup}>
        <label className={classes.label} htmlFor="menu-category-color">
          選択中カテゴリーの色
        </label>
        <AdminColorInput id="menu-category-color" value={design.menuCategoryColor} onChange={changeCategoryColor} />
      </div>
      <div className={classes.inputGroup}>
        <label className={classes.label} htmlFor="menu-background-color">
          背景色
        </label>
        <AdminColorInput
          id="menu-background-color"
          value={design.menuBackgroundColor}
          onChange={changeBackgroundColor}
        />
      </div>
      <div className={classes.inputGroup}>
        <label className={classes.label} htmlFor="menu-font-family">
          フォント
        </label>
        <AdminSelect
          id="menu-font-family"
          selectProps={FontSelectProps}
          selectedValue={design.menuFontFamily}
          onChange={changeFontFamily}
        />
      </div>
      <AdminButton color="default" text="元に戻す" onClick={resetForm} />
      <AdminButton className={classes.saveButton} color="positive" text="保存" onClick={saveDesign} />
    </div>
  );
};
