import { makeStyles } from '@material-ui/core';
import { DTODesign } from 'models';
import React, { FC, useMemo } from 'react';
import { getPoliticColor } from '../../../utils/colorTranslator';
import { ClassName } from '../../general/props/classname';
import { TabItem, TabItemProps } from '../parts/tabItem';

type Props = ClassName & {
  items: TabItemProps[];
  onTabClick?: (id: TabItemProps['id']) => void;
  designState: DTODesign | undefined;
};

const useStyles = makeStyles((theme) => ({
  tabbar: {
    position: 'relative',
    display: 'flex',
    padding: '0.5rem 0',
    overflowX: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&:after': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backdropFilter: 'blur(1rem)',
      left: 0,
      top: 0,
      content: '""',
      zIndex: 0,
    },
  },
  item: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    zIndex: 1,
  },
  whiteSpace: {
    width: theme.spacing(1),
    height: '1px',
    flexShrink: 0,
  },
}));

export const TabBar: FC<Props> = ({ className, items, onTabClick, designState }) => {
  const classes = useStyles();

  const LIGHT = 'rgb(255,255,255,0.8)';
  const DARK = 'rgba(0, 0, 0, 0.05)';
  const backgroundColor = useMemo(() => getPoliticColor(designState?.menuBackgroundColor, LIGHT, DARK, DARK), [
    designState?.menuBackgroundColor,
  ]);

  const onClickFactory = (id: TabItemProps['id']) => {
    if (onTabClick) {
      return () => {
        onTabClick(id);
      };
    }
    return undefined;
  };

  return (
    <div className={`${classes.tabbar} ${className}`} style={{ background: `${backgroundColor}` }}>
      {items.map((item) => (
        <TabItem
          key={item.id}
          className={classes.item}
          id={item.id}
          text={item.text}
          selected={item.selected}
          onClick={onClickFactory(item.id)}
          designState={designState}
        />
      ))}
      <span className={classes.whiteSpace} />
    </div>
  );
};
