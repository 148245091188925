import { makeStyles } from '@material-ui/core';
import { DTODesign, DTOProduct } from 'models';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';
import { PreviewCard } from './previewCard';

type Props = ClassName & {
  products: DTOProduct[];
  designState: DTODesign | undefined;
};

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: theme.spacing(2),
  },
  checkBox: {
    color: theme.palette.secondary.dark,
    width: '5%',
  },
  keepDrawer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const PreviewCardList: FC<Props> = ({ className, products, designState }) => {
  const classes = useStyles();

  return (
    <div className={className}>
      {products.map((product) => (
        <PreviewCard key={product.id} className={classes.item} product={product} designState={designState} />
      ))}
    </div>
  );
};
