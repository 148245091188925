import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import { DTODesign } from 'models';
import React, { FC, useMemo } from 'react';
import { getPoliticColor } from '../../../utils/colorTranslator';
import { ClassName } from '../../general/props/classname';

export interface TabItemProps {
  id: number;
  text: string;
  selected?: boolean;
}

export type Props = ClassName &
  TabItemProps & {
    onClick?: () => void;
    designState: DTODesign | undefined;
  };

const useStyles = makeStyles((theme) => ({
  tab: {
    display: 'flex',
    padding: '0.4rem 1.2rem',
    borderRadius: '1rem',
    backgroundColor: '#ffffff',
    flexShrink: 0,
    flexGrow: 0,
    alignItems: 'center',
    boxShadow: '2px 2px 3px rgba(0,0,0,0.5)',
  },
  selected: {
    backgroundColor: '#ededed',
    boxShadow: 'none',
  },
  text: {
    fontSize: '1rem',
    fontWeight: 700,
  },
}));

export const TabItem: FC<Props> = ({ className, id, text, selected = false, onClick, designState }) => {
  const classes = useStyles();

  const LIGHT = '#ffffff';
  const DARK = '#000000';
  const categoryNameColor = useMemo(() => getPoliticColor(designState?.menuCategoryColor, LIGHT, DARK, LIGHT), [
    designState?.menuCategoryColor,
  ]);

  return (
    <ButtonBase
      className={`${classes.tab} ${selected && classes.selected} ${className}`}
      onClick={onClick}
      style={selected && designState ? { backgroundColor: `${designState.menuCategoryColor}` } : {}}
    >
      <Typography
        className={classes.text}
        variant="h5"
        style={
          designState && {
            fontFamily: `${designState.menuFontFamily}, sans-serif`,
            color: selected ? categoryNameColor : 'inherit',
          }
        }
      >
        {text}
      </Typography>
    </ButtonBase>
  );
};
