import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';

interface SelectProps {
  value: string;
  text: string;
}

type Props = ClassName & {
  selectProps: SelectProps[];
  selectedValue: string;
  id?: string;
  disabled?: boolean;
  onChange: (props: string) => void;
};

const useStyles = makeStyles((theme) => ({
  wrapper: { backgroundColor: '#ffffff' }, // widthやheightは親要素で指定する
}));

export const AdminSelect: FC<Props> = ({ className, selectProps, selectedValue, id, disabled = false, onChange }) => {
  const classes = useStyles();

  const handleChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    if (typeof e.target.value === 'string') {
      onChange(e.target.value);
    }
  };

  return (
    <FormControl variant="outlined" className={classes.wrapper}>
      <Select id={id} value={selectedValue} onChange={handleChange} className={className} disabled={disabled}>
        {selectProps.map((selectProp) => (
          <MenuItem key={selectProp.value} value={`${selectProp.value}`}>
            {selectProp.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
