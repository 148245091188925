import { makeStyles } from '@material-ui/core';
import { DTODesign } from 'models';
import React, { FC, useContext, useMemo, useState } from 'react';
import { fonts } from '../../../constants/general/designs';
import { menuActions, MenuContext, menuSelectors } from '../../../modules/settings/menu';
import { FunctionsMenuButton } from '../../general/parts/functionsMenuButton';
import { ClassName } from '../../general/props/classname';
import { Header } from '../parts/header';
import { TabItemProps } from '../parts/tabItem';
import { KeepListBottomNavigation } from './keepListBottomNavigation';
import { PreviewCardList } from './previewCardList';
import { TabBar } from './tabBar';

type Props = ClassName & {
  design: DTODesign | undefined;
  image?: string;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
  },
  forbidScroll: {
    height: '0.01vh',
    overflowY: 'hidden',
  },
  contents: {
    paddingBottom: theme.menuFooter?.height,
    minHeight: '100vh',
    backgroundColor: '#ffffff',
  },
  previewContents: {
    paddingBottom: '0px',
    minHeight: '100vh',
    backgroundColor: '#ffffff',
  },
  productsWrapper: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  footer: {
    position: 'sticky',
    paddingBottom: 0,
  },
  lightDrawer: {
    background: theme.menuLightDrawer?.footer.background,
    height: theme.menuLightDrawer?.footer.height,
  },
  darkDrawer: {
    background: theme.menuDarkDrawer?.footer.background,
    height: theme.menuDarkDrawer?.footer.height,
  },
  categoryTab: {
    position: 'sticky',
    '&:: -webkit-sticky': { position: 'sticky' },
    top: 0,
    zIndex: 1,
  },
  previewWrapper: {
    height: '100%',
    backgroundColor: '#ffffff',
    position: 'relative',
  },
  preview: {
    width: 'calc(100% - 4rem)',
    maxWidth: '414px',
    height: 'calc(90% - 4.5rem)',
    margin: '2rem auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: '0 0 4px #aaa inset',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: '#c7c7c7',
    },
  },
  previewFooter: {
    position: 'sticky',
    zIndex: 0,
    top: '100%',
    maxWidth: '414px',
  },
  functionsMenuButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '2rem 2rem 0',
  },
}));

/**
 * メニューのトップページに必要なコンポーネントたちの集合
 */
export const Preview: FC<Props> = ({ className, design, image }) => {
  const menuStore = useContext(MenuContext);
  const [products, setProducts] = useState(menuSelectors.getSelectedProducts(menuStore.state));
  const [tabItems, setTabItems] = useState(menuSelectors.getCategoriesAsTabItemProps(menuStore.state));
  const classes = useStyles();

  useMemo(() => {
    setProducts(menuSelectors.getSelectedProducts(menuStore.state));
    setTabItems(menuSelectors.getCategoriesAsTabItemProps(menuStore.state));
  }, [menuStore.state.menu.categories, menuStore.state.selectedCategoryId]);

  const onTabClick = (id: TabItemProps['id']) => {
    menuStore.dispatch(menuActions.categoryTabClicked(id));
  };

  const header = useMemo(() => {
    return <Header designState={design} image={image} />;
  }, [design]);

  const tabBar = useMemo(() => {
    return <TabBar items={tabItems} onTabClick={onTabClick} designState={design} />;
  }, [tabItems, design]);

  const previewCardList = useMemo(() => {
    return <PreviewCardList products={products} designState={design} />;
  }, [products, design]);

  return (
    <div className={`${classes.previewWrapper} ${className}`}>
      <FunctionsMenuButton className={classes.functionsMenuButton} />
      <div className={classes.preview}>
        {/* NOTE:このコンポーネントが再描画されてもフォントは1度しか読み込まれないよ */}
        {design && (
          <>
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link href={fonts[design.headerFontFamily]} rel="stylesheet" />
            {design.headerFontFamily !== design.menuFontFamily && (
              <link href={fonts[design.menuFontFamily]} rel="stylesheet" />
            )}
          </>
        )}
        <div className={classes.wrapper}>
          <div className={classes.previewContents} style={design && { background: `${design.menuBackgroundColor}` }}>
            <div>
              {header}
              <div className={classes.categoryTab} style={design && { background: `${design.menuBackgroundColor}` }}>
                {tabBar}
              </div>
              <div className={classes.productsWrapper}>{previewCardList}</div>
              <KeepListBottomNavigation className={classes.footer} badgeContent={0} designState={design} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
