interface Fonts {
  [key: string]: string;
}

export const fonts: Fonts = {
  'Noto Serif JP': 'https://fonts.googleapis.com/css2?family=Noto+Serif+JP&display=swap',
  'Noto Sans JP': 'https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap',
};

export const Fonts = ['Noto Serif JP', 'Noto Sans JP'];
export const FontSelectProps = Fonts.map((font) => ({ value: font, text: font }));
export const FontSizes = [...Array(42).keys()].filter((n) => n % 2 === 0).map((n) => `${n}`);
export const FontSizeProps = FontSizes.map((size) => ({ value: size, text: size }));
