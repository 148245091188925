import { makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  text: string;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '0.5rem 0',
    borderBottom: '1px solid #8f8f8f',
  },
  text: {
    fontWeight: 600,
  },
}));

/** でかい文字と下線がついてる子 */
export const AdminTitle: FC<Props> = ({ className, text }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.wrapper} ${className}`}>
      <Typography className={classes.text} variant="h4">
        {text}
      </Typography>
    </div>
  );
};
